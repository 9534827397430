var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px", "margin-bottom": "0" },
              attrs: {
                "prefix-icon": "el-icon-search",
                size: "small",
                placeholder: "关键字",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleFilter.apply(null, arguments)
                },
              },
              model: {
                value: _vm.listQuery.key,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "key", $$v)
                },
                expression: "listQuery.key",
              },
            }),
            _c("permission-btn", {
              attrs: { size: "mini" },
              on: { "btn-event": _vm.onBtnClicked },
            }),
            _c(
              "el-checkbox",
              {
                staticStyle: { "margin-left": "15px" },
                attrs: { size: "small" },
                on: {
                  change: function ($event) {
                    _vm.tableKey = _vm.tableKey + 1
                  },
                },
                model: {
                  value: _vm.showDescription,
                  callback: function ($$v) {
                    _vm.showDescription = $$v
                  },
                  expression: "showDescription",
                },
              },
              [_vm._v("Id/描述")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "app-container flex-item" },
        [
          _c(
            "div",
            { staticClass: "bg-white fh" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  ref: "mainTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.list,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                    height: "calc(100% - 52px)",
                  },
                  on: {
                    "row-click": _vm.rowClick,
                    "selection-change": _vm.handleSelectionChange,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", type: "selection", width: "55" },
                  }),
                  _vm.showDescription
                    ? _c("el-table-column", {
                        attrs: { label: "Id", "min-width": "120px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.id))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          773642443
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { "min-width": "50px", label: "角色名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { "min-width": "300px", label: "用户列表" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return _vm.list.length > 0
                              ? [
                                  _c("role-users", {
                                    ref: "roleUser",
                                    attrs: {
                                      "role-id": scope.row.id,
                                      selectUsers:
                                        _vm.roleUsers.list[scope.$index],
                                    },
                                    on: {
                                      "update:selectUsers": function ($event) {
                                        return _vm.$set(
                                          _vm.roleUsers.list,
                                          scope.$index,
                                          $event
                                        )
                                      },
                                      "update:select-users": function ($event) {
                                        return _vm.$set(
                                          _vm.roleUsers.list,
                                          scope.$index,
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "class-name": "status-col",
                      label: "状态",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                class: _vm._f("statusFilter")(scope.row.status),
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.statusOptions.find(function (u) {
                                      return u.key == scope.row.status
                                    }).display_name
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "操作",
                      width: "230",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUpdate(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            scope.row.status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleModifyStatus(
                                          scope.row,
                                          1
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("停用")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              staticClass: "dialog-mini",
              attrs: {
                width: "500px",
                title: _vm.textMap[_vm.dialogStatus],
                visible: _vm.dialogFormVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.temp,
                    "label-position": "right",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dialogStatus == "update",
                          expression: "dialogStatus=='update'",
                        },
                      ],
                      attrs: { size: "small", label: "Id", prop: "id" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: true, placeholder: "系统自动处理" },
                        model: {
                          value: _vm.temp.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "id", $$v)
                          },
                          expression: "temp.id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { size: "small", label: "角色名称", prop: "name" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.temp.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "name", $$v)
                          },
                          expression: "temp.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: { placeholder: "Please select" },
                          model: {
                            value: _vm.temp.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "status", $$v)
                            },
                            expression: "temp.status",
                          },
                        },
                        _vm._l(_vm.statusOptions, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: {
                              label: item.display_name,
                              value: item.key,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm.dialogStatus == "create"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.createData },
                        },
                        [_vm._v("确认")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.updateData },
                        },
                        [_vm._v("确认")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              ref: "accessModulesDlg",
              staticClass: "dialog-mini",
              attrs: {
                title: _vm.accessTitle,
                visible: _vm.dialogAccessModules,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogAccessModules = $event
                },
              },
            },
            [
              _vm.dialogAccessModules
                ? _c("access-modules", {
                    ref: "accessModules",
                    attrs: { "role-id": _vm.multipleSelection[0].id },
                    on: {
                      "change-title": _vm.changeTitle,
                      close: function ($event) {
                        _vm.dialogAccessModules = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              staticClass: "dialog-mini",
              attrs: {
                title: "为角色分配资源",
                visible: _vm.dialogAccessResource,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogAccessResource = $event
                },
              },
            },
            [
              _vm.dialogAccessResource
                ? _c("access-resource", {
                    ref: "accessResource",
                    attrs: { "role-id": _vm.multipleSelection[0].id },
                    on: {
                      close: function ($event) {
                        _vm.dialogAccessResource = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              staticClass: "dialog-mini user-dialog",
              attrs: {
                title: "添加角色用户",
                visible: _vm.roleUsers.dialogUserResource,
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(_vm.roleUsers, "dialogUserResource", $event)
                },
              },
            },
            [
              _vm.roleUsers.dialogUserResource
                ? _c("selectUsersCom", {
                    ref: "selectUser",
                    attrs: {
                      hiddenFooter: true,
                      loginKey: "loginUser",
                      users: _vm.assignedUserIds,
                      userNames:
                        _vm.roleUsers.rowIndex > -1 &&
                        _vm.roleUsers.list[_vm.roleUsers.rowIndex]
                          .map(function (u) {
                            return u.name || u.account
                          })
                          .join(","),
                    },
                    on: {
                      "update:users": function ($event) {
                        _vm.assignedUserIds = $event
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "cancel" },
                      on: {
                        click: function ($event) {
                          _vm.roleUsers.dialogUserResource = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSaveUsers },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }