var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.users, function (item, index) {
        return _c(
          "el-tag",
          {
            key: index,
            staticStyle: { "margin-right": "4px" },
            attrs: { size: "mini" },
          },
          [_vm._v(_vm._s(item))]
        )
      }),
      _vm.buttonVisible
        ? _c("el-button", {
            staticStyle: { height: "20px", padding: "0 10px" },
            attrs: {
              type: "primary",
              loading: _vm.isLoading,
              plain: "",
              title: "查看更多",
              size: "mini",
              icon: "el-icon-more",
            },
            on: { click: _vm.loadRoleUsers },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }